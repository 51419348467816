<template>
  <label>
    <slot></slot>
    <em class="text-danger" v-if="required">*</em>
    <CButton
      color="link"
      size="sm"
      v-c-popover="{
        header,
        content,
      }"
    >
      <font-awesome-icon icon="question-circle" />
    </CButton>
  </label>
</template>

<script>
export default {
  name: "LabelHelp",
  props: {
    header: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}
</style>
